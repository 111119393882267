/**
 * Post Styles
 * Styles for blog posts and post listings
 */

/* Post Content */
.post-content {
  font-size: 1rem;
  margin: 0 auto;
  padding: 0;
  display: block;
  font-family: var(--font-sans);
  overflow-x: hidden;
  max-width: 56rem;
}

.post-content-inner {
  margin: 0 auto;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

/* Post Header */
.post-header {
  padding-top: 5rem;
  padding-bottom: 2.5rem;
  text-align: center;
  max-width: 56rem;
  margin: 0 auto;
}

/* Page Header */
.page-header {
  color: var(--color-header-text);
  position: relative;
}

/* Page Content */
.page-content {
  font-size: 1rem;
  margin: 0 auto;
  padding: 0;
  display: block;
  font-family: var(--font-sans);
  overflow-x: hidden;
  max-width: 56rem;
  padding-bottom: 5rem;
}

/* Feed container */
.feed-container {
  max-width: 56rem;
  margin: 0 auto;
  padding: 0.5rem 1rem 1rem;
}

.feed-link-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.feed-link {
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-text-muted);
  transition: color 0.2s;
  white-space: nowrap;
  align-items: center;
}

.feed-link:hover {
  color: var(--color-primary);
}

.feed-icon {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
}

.post-title {
  color: var(--color-text);
  font-weight: 800;
  font-size: 3rem;
  margin: 0 auto;
  text-transform: uppercase;
  font-family: var(--font-heading);
}

.post-meta {
  margin-top: 0;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
  font-family: var(--font-sans);
}

/* Post List Items */
.post-item {
  padding-top: 2.5rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid var(--color-border);
  max-width: 56rem;
  margin-left: auto;
  margin-right: auto;
}

.post-item:first-child {
  padding-top: 1.5rem;
}

.post-item-date-column {
  margin-bottom: 1rem;
}

.post-item-date {
  display: flex;
  align-items: baseline;
}

.post-item-day {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: var(--font-heading);
  color: var(--color-primary);
  margin-right: 0.5rem;
}

.post-item-month-year {
  color: var(--color-text-muted);
  font-size: 0.875rem;
  font-family: var(--font-heading);
  text-transform: uppercase;
}

.post-item-title {
  color: var(--color-heading);
  font-weight: 700;
  font-family: var(--font-heading);
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}

.post-item-title a {
  transition: color 0.2s;
}

.post-item-title a:hover {
  color: var(--color-primary);
}

.post-item-excerpt {
  color: var(--color-text-muted);
  font-size: 1rem;
  font-family: var(--font-sans);
  margin-bottom: 1rem;
}

.post-item-categories {
  font-size: 0.75rem;
  font-family: var(--font-sans);
}

.post-item-category {
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem; 
  padding: 0.25rem 0.75rem;
  color: var(--color-text-muted);
  border: 1px solid var(--color-border);
  border-radius: 0.375rem;
  transition: background-color 0.2s;
}

.post-item-category:hover {
  background-color: var(--color-surface-hover);
}

/* Media Queries */
@media (min-width: 640px) {
  .post-title {
    font-size: 4.5rem;
  }
  
  .post-item {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  .post-item:first-child {
    padding-top: 2rem;
  }
  
  .post-item-flex {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .post-item-date-column {
    width: auto;
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
  
  .post-item-date {
    display: block;
    text-align: center;
  }
  
  .post-item-day {
    font-size: 3rem;
    margin-right: 0;
  }
  
  .post-item-content {
    flex: 1;
  }
  
  .post-item-title {
    font-size: 1.875rem;
  }
  
  .post-item-excerpt {
    font-size: 1.125rem;
  }
}

@media (min-width: 768px) {
  .post-item-date-column {
    margin-right: 2rem;
  }
}
