/**
 * Header Styles
 * Styling for the site header and hero area
 */

/* Main site header */
.site-header {
  color: var(--color-header-text);
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
  background-size: cover;
  background-position: center;
}

/* Header position */
header.relative {
  position: relative;
  display: block;
}

/* Site title container */
.site-header h1 {
  padding-top: 2.5rem;
  margin-top: 0;
  position: relative;
  z-index: 2;
}

@media (min-width: 1024px) {
  .site-header h1 {
    padding-top: 8rem;
  }
}

/* Site title styles */
.site-title {
  background-color: var(--color-primary);
  color: white;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  cursor: default;
  box-sizing: border-box;
  font-family: var(--font-heading);
}

/* Site description styles */
.site-description {
  color: var(--color-header-text);
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  padding: 0;
  padding-bottom: 5rem;
  margin: 0;
  margin-top: 1.25rem;
  font-family: var(--font-sans);
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  cursor: default;
  line-height: 1.3;
  text-rendering: optimizelegibility;
}

@media (min-width: 640px) {
  .site-description {
    font-size: 3.75rem;
    font-weight: 700;
    padding-bottom: 8rem;
  }
}

@media (min-width: 1024px) {
  .site-description {
    font-size: 6rem;
    font-weight: 700;
    padding-bottom: 10rem;
  }
}
