/**
 * Typography styles
 * Text styling, headings, paragraphs, links, etc.
 */

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  color: var(--color-heading);
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-2);
  font-weight: 600;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* Links */
a,
a:active,
a:hover,
a:visited {
  color: var(--color-primary-light);
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: var(--color-primary);
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, h6 a:focus,
h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {
  color: var(--color-heading);
  text-decoration: none;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  color: var(--color-primary);
}

/* Paragraphs and spacing */
p {
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
}

.post-content p {
  margin-bottom: var(--spacing-6);
}

/* Code formatting inside text */
p code,
a code {
  background-color: var(--color-code-bg);
  color: var(--color-text);
  padding: 0.2em 0.4em;
  border-radius: var(--radius-sm);
  font-size: 0.9em;
  font-weight: 600;
}

a code {
  color: var(--color-primary-dark);
}

/* Lists */
.post-content ul,
.post-content ol {
  margin-bottom: var(--spacing-4);
  padding-left: var(--spacing-6);
}

.post-content ul li,
.post-content ol li {
  margin-bottom: var(--spacing-2);
}

.post-content ul {
  list-style-type: disc;
}

.post-content ol {
  list-style-type: decimal;
}

.post-content li ul,
.post-content li ol {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  margin-left: var(--spacing-4);
}

/* Blockquotes */
blockquote {
  border-left: 4px solid var(--color-border);
  margin: var(--spacing-8) var(--spacing-4);
  padding-left: var(--spacing-4);
  font-style: italic;
  color: var(--color-text-muted);
}

blockquote p {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}

/* Horizontal rule */
hr {
  border: 0;
  border-top: 1px solid var(--color-border);
  margin: var(--spacing-6) 0;
  clear: both;
}

/* Tables */
table {
  width: 100%;
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
  border-collapse: collapse;
}

table th {
  background-color: var(--color-surface-hover);
  padding: var(--spacing-2);
  text-align: left;
  font-weight: 600;
}

table td {
  border: 1px solid var(--color-border-light);
  padding: var(--spacing-2);
}

/* Helper text classes */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-muted {
  color: var(--color-text-muted);
}

.text-small {
  font-size: 0.875rem;
}