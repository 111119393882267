/**
 * Signoff Styles
 * Styles for the author signoff section at the bottom of posts
 */

.author-section {
  padding: 3.5rem 0;
  margin: 0;
}

.author-content {
  margin: 0 auto;
  width: 100%;
}

.author-inner {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  text-align: center;
}

.author-avatar {
  width: 8rem;
  height: 8rem;
  border-radius: 9999px;
  margin-right: 0;
  margin-bottom: 1.25rem;
  display: inline-block;
  vertical-align: middle;
}

.author-label {
  display: block;
  font-size: 1rem;
  color: var(--color-text-muted);
  font-style: italic;
}

.author-name {
  margin: 0;
  padding: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.author-email {
  margin-bottom: 2rem;
}

.author-email a {
  color: var(--color-primary);
}

.author-email a:hover,
.author-email a:focus {
  color: var(--color-primary);
}

.author-bio {
  font-family: var(--font-sans);
  font-size: 1.125rem;
}

/* Media Queries */
@media (min-width: 768px) {
  .author-avatar {
    width: 12rem;
    height: 12rem;
  }
}
