/**
 * Components styles
 * Reusable UI components like buttons, cards, etc.
 */

/* Buttons */
.btn {
  display: inline-block;
  padding: var(--spacing-2) var(--spacing-4);
  border-radius: var(--radius);
  font-weight: 600;
  text-align: center;
  transition: all 0.2s;
  cursor: pointer;
  border: 1px solid transparent;
}

.btn-primary {
  background-color: var(--color-primary);
  color: white;
}

.btn-primary:hover {
  background-color: var(--color-primary-dark);
  color: white;
}

.btn-outline {
  background-color: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.btn-outline:hover {
  background-color: var(--color-primary);
  color: white;
}

/* Cards */
.card {
  background-color: var(--color-surface);
  border-radius: var(--radius);
  border: 1px solid var(--color-border);
  overflow: hidden;
  transition: all 0.2s;
}

.card:hover {
  box-shadow: var(--shadow-md);
}

.card-header {
  padding: var(--spacing-4);
  border-bottom: 1px solid var(--color-border);
}

.card-body {
  padding: var(--spacing-4);
}

.card-footer {
  padding: var(--spacing-4);
  border-top: 1px solid var(--color-border);
}

/* Article styling */
article {
  margin-bottom: var(--spacing-8);
}

.article {
  margin-bottom: var(--spacing-10);
}

.article-title {
  font-size: 1.75rem;
  margin-bottom: var(--spacing-2);
}

.article-meta {
  font-size: 0.875rem;
  color: var(--color-text-muted);
  margin-bottom: var(--spacing-4);
}

.article-excerpt {
  margin-bottom: var(--spacing-4);
}

/* Navigation */
.nav-menu .nav-link {
  display: inline-block;
  padding: var(--spacing-2) var(--spacing-4);
  font-weight: 600;
}

.nav-logo {
  font-weight: 700;
  font-size: 1.25rem;
}

/* Code blocks (beyond inline code) */
pre {
  background-color: var(--color-code-bg);
  border-radius: var(--radius);
  padding: var(--spacing-4);
  margin: var(--spacing-4) 0;
  overflow-x: auto;
}

/* Prism toolbar */
div.code-toolbar > .toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.code-toolbar > .toolbar .toolbar-item {
  display: unset;
  padding: 0 0.3em; 
}

/* Fix for prism operator tokens */
.token.operator {
  background: none;
}

/* Tags */
.tag {
  display: inline-block;
  background-color: var(--color-surface-hover);
  border-radius: var(--radius-lg);
  padding: var(--spacing-1) var(--spacing-3);
  font-size: 0.75rem;
  font-weight: 600;
  margin-right: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}

.tag:hover {
  background-color: var(--color-primary-light);
  color: white;
}

/* Pagination */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: var(--spacing-8) 0;
  justify-content: center;
}

.pagination-item {
  margin: 0 var(--spacing-1);
}

.pagination-link {
  display: block;
  padding: var(--spacing-2) var(--spacing-3);
  border: 1px solid var(--color-border);
  border-radius: var(--radius);
}

.pagination-link.active {
  background-color: var(--color-primary);
  color: white;
  border-color: var(--color-primary);
}

.pagination-link:hover:not(.active) {
  background-color: var(--color-surface-hover);
}
