/**
 * Footer Styles
 * Styling for the site footer
 */

/* Main footer container */
footer {
  background-color: black;
  position: relative;
}

/* Coffee button container */
.footer-coffee {
  display: flex;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* Container for copyright and social sections */
footer::after {
  content: "";
  display: table;
  clear: both;
}

/* Copyright section */
.footer-copyright {
  color: white;
  text-align: center;
  font-size: 0.875rem;
  padding: 1.5rem;
}

/* Social media section */
.footer-social {
  text-align: center;
  padding: 1.5rem;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  footer {
    display: flex;
    flex-wrap: wrap;
  }
  
  .footer-coffee {
    width: 100%;
  }
  
  .footer-copyright, .footer-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2rem;
  }
  
  .footer-copyright {
    text-align: left;
    flex: 1;
  }
  
  .footer-social {
    text-align: right;
  }
}

/* Larger screens */
@media (min-width: 1024px) {
  .footer-copyright, .footer-social {
    padding: 3rem;
  }
}

/* Coffee button styling */
.bmc-button {
  line-height: 36px;
  height: 37px;
  text-decoration: none;
  display: inline-flex;
  color: #FFFFFF !important;
  background-color: #FF813F;
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 1px 9px;
  font-size: 22px;
  letter-spacing: 0.6px;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5);
  margin: 0 auto;
  font-family: 'Cookie', cursive;
  box-sizing: border-box;
  transition: 0.3s all linear;
}

.bmc-button:hover, 
.bmc-button:active, 
.bmc-button:focus {
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  text-decoration: none;
  opacity: 0.85;
  color: #FFFFFF;
}

.bmc-button img {
  width: 27px;
  margin-bottom: 1px;
  box-shadow: none;
  border: none;
  vertical-align: middle;
}

.bmc-button span {
  margin-left: 5px;
}

/* Footer links */
.footer-copyright a,
.footer-social a {
  text-decoration: none;
}

.footer-copyright a:hover,
.footer-social a:hover {
  color: var(--color-primary-light);
}

/* Section spacing */
.footer-copyright section {
  margin-bottom: 0.5rem;
}

/* Social icons */
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}

.fa-stack-1x, .fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}
