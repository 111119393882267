/**
 * Theme System
 * Centralized color and theme variables with clear priority order:
 * 1. Default theme (light) - Base level in :root
 * 2. User choice - Using .light-mode or .dark-mode classes (highest priority)
 */

/* ============================================================================
   DEFAULT THEME (LIGHT MODE)
   Base level variables in :root scope
============================================================================ */
:root {
  /* Primary brand colors - used in both light and dark modes */
  --color-primary: #e51843;
  --color-primary-light: #ef6281;
  --color-primary-dark: #c9163d;
  
  /* Light mode values */
  /* Background and text colors */
  --light-color-background: #ffffff;
  --light-color-text: #333333;
  --light-color-text-muted: #718096;
  --light-color-heading: #111827;
  
  --light-color-surface: #ffffff;
  --light-color-surface-hover: #f7fafc;
  
  --light-color-border: #e2e8f0;
  --light-color-border-light: #edf2f7;
  
  --light-color-code-bg: #f7fafc;
  --light-color-code-text: #333333;
  
  --light-color-header-text: #000;
  
  --light-shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --light-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --light-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  
  /* Pre-defined dark mode values that will be overridden by html element class */
  --dark-color-background: #121212;
  --dark-color-text: #f0f0f0;
  --dark-color-text-muted: #cbd5e0;
  --dark-color-heading: #ffffff;
  --dark-color-surface: #1e293b;
  --dark-color-surface-hover: #334155;
  --dark-color-border: #3d4852;
  --dark-color-border-light: #606f7b;
  --dark-color-code-bg: #2d3748;
  --dark-color-code-text: #f8f8f2;
  --dark-color-header-bg: #121212;
  --dark-color-header-text: #ffffff;
  --dark-shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  --dark-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  --dark-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

  /* Apply light mode by default */
  --color-background: var(--light-color-background);
  --color-text: var(--light-color-text);
  --color-text-muted: var(--light-color-text-muted);
  --color-heading: var(--light-color-heading);

  --color-background: var(--light-color-background);
  --color-text: var(--light-color-text);
  --color-text-muted: var(--light-color-text-muted);
  --color-heading: var(--light-color-heading);
  
  --color-surface: var(--light-color-surface);
  --color-surface-hover: var(--light-color-surface-hover);
  
  --color-border: var(--light-color-border);
  --color-border-light: var(--light-color-border-light);
  
  --color-code-bg: var(--light-color-code-bg);
  --color-code-text: var(--light-color-code-text);
  
  --color-header-text: var(--light-color-header-text);
  
  --shadow-sm: var(--light-shadow-sm);
  --shadow: var(--light-shadow);
  --shadow-md: var(--shadow-md);
}

/* ============================================================================
   USER CHOICE: DARK MODE - Highest priority
   Applied when user explicitly chooses dark theme
============================================================================ */
html.dark-mode {
  /* Background and text colors */
  --color-background: var(--dark-color-background) !important;
  --color-text: var(--dark-color-text) !important;
  --color-text-muted: var(--dark-color-text-muted) !important;
  --color-heading: var(--dark-color-heading) !important;
  
  /* Surface elements */
  --color-surface: var(--dark-color-surface) !important;
  --color-surface-hover: var(--dark-color-surface-hover) !important;
  
  /* Borders */
  --color-border: var(--dark-color-border) !important;
  --color-border-light: var(--dark-color-border-light) !important;
  
  /* Code blocks */
  --color-code-bg: var(--dark-color-code-bg) !important;
  --color-code-text: var(--dark-color-code-text) !important;
  
  /* Header specific colors */
  --color-header-bg: var(--dark-color-header-bg) !important;
  --color-header-text: var(--dark-color-header-text) !important;
  
  /* Shadows */
  --shadow-sm: var(--dark-shadow-sm) !important;
  --shadow: var(--dark-shadow) !important;
  --shadow-md: var(--dark-shadow-md) !important;
}

/* Dark mode specific component overrides */
html.dark-mode .site-header::before {
  background-color: rgba(0, 0, 0, 0.5) !important; /* Less opaque in dark mode */
}

html.dark-mode img {
  opacity: 0.9 !important;
}

html.dark-mode pre {
  background-color: #282a36 !important; /* Dracula theme background */
  border: 1px solid #44475a !important; /* Dracula comment color */
  color: #f8f8f2 !important; /* Dracula foreground */
}

html.dark-mode pre code {
  color: #f8f8f2 !important; /* Dracula foreground */
}

html.light-mode {
  /* Background and text colors */
  --color-background: var(--light-color-background) !important;
  --color-text: var(--light-color-text) !important;
  --color-text-muted: var(--light-color-text-muted) !important;
  --color-heading: var(--light-color-heading) !important;
  
  /* Surface elements */
  --color-surface: var(--light-color-surface) !important;
  --color-surface-hover: var(--light-color-surface-hover) !important;
  
  /* Borders */
  --color-border: var(--light-color-border) !important;
  --color-border-light: var(--light-color-border-light) !important;
  
  /* Code blocks */
  --color-code-bg: var(--light-color-code-bg) !important;
  --color-code-text: var(--light-color-code-text) !important;
  
  /* Header specific colors */
  --color-header-text: var(--light-color-header-text) !important;
  
  /* Shadows */
  --shadow-sm: var(--light-shadow-sm) !important;
  --shadow: var(--light-shadow) !important;
  --shadow-md: var(--light-shadow-md) !important;
}

/* ============================================================================
   TAILWIND CSS SPECIFIC OVERRIDES
   These ensure Tailwind's utility classes don't override our theme
============================================================================ */

/* Force proper text colors in light mode */
html.light-mode .text-gray-900,
html.light-mode .text-gray-800,
html.light-mode .text-gray-700,
html.light-mode .text-black {
  color: var(--color-text) !important;
}

html.light-mode .text-gray-600,
html.light-mode .text-gray-500 {
  color: var(--color-text-muted) !important;
}

/* Force proper text colors in dark mode */
html.dark-mode .text-gray-900,
html.dark-mode .text-gray-800,
html.dark-mode .text-gray-700,
html.dark-mode .text-black {
  color: var(--color-text) !important;
}

html.dark-mode .text-gray-600,
html.dark-mode .text-gray-500 {
  color: var(--color-text-muted) !important;
}

/* ============================================================================
   CONTENT-SPECIFIC OVERRIDES
   These ensure consistent styling across post content
============================================================================ */

/* Extra specificity for important elements in light mode */
html.light-mode .post-content p,
html.light-mode .post-content li,
html.light-mode .article-content p,
html.light-mode .article-content li,
html.light-mode .micro-post-content p {
  color: var(--color-text) !important;
}

/* Extra specificity for important elements in dark mode */
html.dark-mode .post-content p,
html.dark-mode .post-content li,
html.dark-mode .article-content p,
html.dark-mode .article-content li,
html.dark-mode .micro-post-content p {
  color: var(--color-text) !important;
}
