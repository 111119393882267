/**
 * Main stylesheet for blog.carlfurrow.com
 * Organizes and imports all component stylesheets
 */

/* Import component stylesheets */
@import './components/base.css';
@import './components/theme.css'; /* Theme should be early in the cascade */
@import './components/typography.css';
@import './components/layout.css';
@import './components/navigation.css';
@import './components/header.css';
@import './components/components.css';
@import './components/micro-posts.css';
@import './components/utilities.css';
@import './components/dark-mode.css';
@import './components/posts.css';
@import './components/signoff.css';
@import './components/footer.css';

@import './vendor/jekyll_img.css';

/* Fix for highlight tables */
.highlight table.rouge-table {
  background: inherit;
  border: 0;
}

.highlight table.rouge-table td {
  color: inherit;
}

/* Utility classes that don't fit elsewhere */
.align-super {
  vertical-align: super;
}

.clear-table {
  clear: both;
}
