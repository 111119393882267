/**
 * Navigation Component
 * Responsive top navigation with hamburger menu for mobile
 */

/* 
 * Main Navigation Container
 * Holds the logo and navigation items
 */
.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
  background-color: var(--color-surface);
  border-bottom: 1px solid var(--color-border);
}

/* 
 * Logo Container 
 * Left side of the navigation
 */
.nav-logo {
  display: flex;
  align-items: center;
  z-index: 20; /* Ensure logo stays above mobile menu */
}

.nav-logo img {
  max-height: 3rem;
  width: auto;
}

/* 
 * Navigation Links Container
 * Right side of the navigation
 */
.nav-links {
  display: flex;
  align-items: center;
}

/* 
 * Navigation Items List
 * The unordered list containing menu items
 */
.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease;
}

/* 
 * Navigation Item
 * Individual list item in the menu
 */
.nav-item {
  margin: 0 0.5rem;
}

/* 
 * Navigation Link
 * The actual link element
 */
.nav-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: var(--color-text);
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.2s ease, border-color 0.2s ease;
  border-bottom: 2px solid transparent;
}

.nav-link:hover {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.nav-link.active {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

/*
 * Theme Switcher
 * Toggle between light, dark, and system themes
 */
.theme-switcher {
  display: flex;
  align-items: center;
}

.theme-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--color-text);
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.theme-toggle:hover {
  background-color: var(--color-surface-hover);
  color: var(--color-primary);
}

.theme-icon {
  font-size: 1.25rem;
  display: none; /* Hidden by default, shown based on active theme */
}

/* Show the appropriate icon based on current theme */
.theme-icon.active {
  display: inline-block;
}

/* 
 * Hamburger Menu Button
 * Mobile toggle button
 */
.nav-toggle {
  display: none; /* Hidden by default on desktop */
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 20; /* Ensure button stays above mobile menu */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.hamburger {
  display: block;
  position: relative;
  width: 2rem;
  height: 1.5rem;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: var(--color-text);
  border-radius: 3px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  top: 0.75rem;
}

.hamburger span:nth-child(4) {
  top: 1.5rem;
}

/* 
 * Open State for Hamburger Menu 
 * When the mobile menu is open
 */
.nav-toggle.is-active .hamburger span:nth-child(1),
.nav-toggle.is-active .hamburger span:nth-child(4) {
  opacity: 0;
}

.nav-toggle.is-active .hamburger span:nth-child(2) {
  transform: rotate(45deg);
}

.nav-toggle.is-active .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
}

/* 
 * Responsive Styles - Mobile 
 * Apply these styles on smaller screens
 */
@media (max-width: 768px) {
  .nav-toggle {
    display: block; /* Show hamburger menu on mobile */
    top: 1rem; /* Align with the top padding of main-nav */
  }

  .nav-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-surface);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 10;
  }

  .nav-menu.is-active {
    transform: translateX(0);
  }

  .nav-item {
    margin: 1rem 0;
  }

  .nav-link {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }
  
  /* Prevent body scrolling when menu is open */
  body.nav-open {
    overflow: hidden;
  }
}

/* 
 * Dark Mode Styles
 * Navigation styles for dark mode
 */
@media (prefers-color-scheme: dark) {
  .main-nav {
    background-color: var(--color-surface);
    border-color: var(--color-border);
  }

  .hamburger span {
    background-color: var(--color-text);
  }

  .nav-menu {
    background-color: var(--color-surface);
  }
}
