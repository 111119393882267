/**
 * Micro Posts styles
 * Specific styles for micro-posts format
 */

/* Main micro post container */
.micro-post {
  border: 1px solid var(--color-border);
  border-radius: var(--radius);
  margin-bottom: var(--spacing-6);
  background-color: var(--color-surface);
  position: relative;
  transition: all 0.2s ease;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}

.micro-post:hover {
  box-shadow: var(--shadow-md);
}

.micro-post-inner {
  padding: var(--spacing-4);
}

/* Post content formatting */
.micro-post-content {
  font-size: 1.125rem;
  line-height: 1.75;
  color: var(--color-text);
}

.micro-post-content p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.micro-post-content p:first-of-type {
  margin-top: 0;
}

.micro-post-content p:last-of-type {
  margin-bottom: 0;
}

/* Spacing between different post types */
.article + .micro-post {
  margin-top: 2.5rem;
}

.micro-post + .article {
  margin-top: 2.5rem;
}

/* Post type indicator */
.micro-post::before {
  display: inline-block;
  position: absolute;
  top: -0.625rem;
  left: 1rem;
  background-color: var(--color-surface);
  padding: 0.125rem 0.5rem;
  font-size: 0.6875rem;
  font-weight: 600;
  color: var(--color-text-muted);
  border: 1px solid var(--color-border);
  border-radius: var(--radius);
  z-index: 1;
}

/* Container spacing adjustments */
.container .micro-post {
  margin-left: auto;
  margin-right: auto;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .micro-post {
    margin-left: 1rem;
    margin-right: 1rem;
    padding: var(--spacing-3);
  }
  
  .micro-post::before {
    left: 0.75rem;
    font-size: 0.625rem;
    padding: 0.125rem 0.375rem;
  }
}

/* Single micro post page */
.micro-post-single {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

/* Thumbnail image styles */
.micro-post.micro-post-item img {
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: var(--radius-md);
  margin: 0.75rem 0;
  transition: opacity 0.2s;
}

.micro-post-image-link {
  display: inline-block;
  position: relative;
}

.micro-post-image-link:hover .micro-post-thumbnail {
  opacity: 0.95;
}

.micro-post-image-link::after {
  content: "↗";
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: rgba(255, 255, 255, 0.85);
  color: var(--color-text);
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius);
  font-size: 0.875rem;
  opacity: 0;
  transition: opacity 0.2s;
}

.micro-post-image-link:hover::after {
  opacity: 1;
}

/* Handle multiple images in a post */
.micro-post-with-thumbnails p:has(.micro-post-image-link) {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  align-items: flex-start;
}

.micro-post-with-thumbnails p .micro-post-image-link {
  flex: 1 1 auto;
  min-width: 150px;
  max-width: 100%;
}

@media (min-width: 640px) {
  .micro-post-with-thumbnails p .micro-post-image-link {
    max-width: 48%;
  }
}

/* Micro post metadata */
.micro-post-meta {
  font-size: 0.875rem;
  color: var(--color-text-muted);
  margin-top: var(--spacing-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.micro-post-date {
  font-weight: 600;
}

.micro-post-permalink {
  font-size: 0.75rem;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.micro-post-permalink:hover {
  opacity: 1;
}

/* Footer with date and tags */
.micro-post-footer {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid var(--color-border);
  font-size: 0.875rem;
  color: var(--color-text-muted);
  display: flex;
  flex-direction: column;
}

.micro-post-time {
  font-weight: 500;
}

.micro-post-time:hover {
  color: var(--color-primary);
}

.micro-post-tags {
  margin-top: 0.75rem;
}

.micro-post-tag {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius-md);
  background-color: var(--color-surface-hover);
  color: var(--color-text-muted);
  font-size: 0.75rem;
  text-decoration: none;
  transition: background-color 0.2s;
}

.micro-post-tag:hover {
  background-color: var(--color-border-light);
}

@media (min-width: 640px) {
  .micro-post-footer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .micro-post-tags {
    margin-top: 0;
  }
}
