/**
 * Theme Mode Styles
 * Supports:
 * 1. Auto dark/light via prefers-color-scheme
 * 2. Forced dark mode via .dark-mode class
 * 3. Forced light mode via .light-mode class
 * 
 * NOTE: All color variables are defined in theme.css.
 * This file only contains mode-specific styling that extends beyond variables.
 */

@media (prefers-color-scheme: dark) {
  /* All CSS variables for dark mode are now defined in theme.css */
  
  /* Core Element Overrides */
  body {
    background-color: var(--color-background);
    color: var(--color-text);
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: var(--color-heading);
  }
  
  /* Link styling adjustments */
  a, a:active, a:hover, a:visited {
    color: var(--color-primary-light);
  }
  
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
  h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, h6 a:focus,
  h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
  h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {
    color: var(--color-heading);
  }
  
  /* Code blocks */
  p code, a code {
    background-color: rgba(45, 55, 72, 0.7); /* Translucent surface */
    color: #f8f8f2; /* Light gray with slight green tint - Dracula compatible */
    border: 1px solid var(--color-border);
  }
  
  a code {
    color: var(--color-primary-light);
  }
  
  pre {
    background-color: #282a36 !important; /* Dracula theme background */
    border: 1px solid #44475a !important; /* Dracula comment color */
    color: #f8f8f2 !important; /* Dracula foreground */
  }
  
  /* Ensure code elements in pre are visible */
  pre code {
    color: #f8f8f2 !important; /* Dracula foreground */
  }
  
  /* Table styling */
  table th {
    background-color: var(--color-surface-hover);
  }
  
  table td {
    border-color: var(--color-border);
  }
  
  /* Button adjustments */
  .btn-outline {
    border-color: var(--color-primary-light);
    color: var(--color-primary-light);
  }
  
  /* Card adjustments */
  .card {
    background-color: var(--color-surface);
    border-color: var(--color-border);
  }
  
  .card-header, .card-footer {
    border-color: var(--color-border);
  }
  
  /* Tag adjustments */
  .tag {
    background-color: var(--color-surface-hover);
    color: var(--color-text);
  }
  
  /* Micro post specific overrides */
  .micro-post {
    background-color: var(--color-surface);
    border-color: var(--color-border);
  }
  
  .micro-post::before {
    background-color: var(--color-surface);
    color: var(--color-text-muted);
    border-color: var(--color-border);
  }
  
  .micro-post-content {
    color: var(--color-text);
  }
  
  .micro-post-image-link::after {
    background-color: rgba(45, 55, 72, 0.85);
    color: var(--color-text);
  }
  
  /* Form elements */
  input, textarea, select {
    background-color: var(--color-surface);
    border-color: var(--color-border);
    color: var(--color-text);
  }
  
  input:focus, textarea:focus, select:focus {
    border-color: var(--color-primary);
  }
  
  /* Pagination */
  .pagination-link {
    border-color: var(--color-border);
    color: var(--color-text);
  }
  
  .pagination-link:hover:not(.active) {
    background-color: var(--color-surface-hover);
  }
  
  /* Images - slight adjustment for dark mode */
  img {
    opacity: 0.9;
  }
  
  /* Override Tailwind's dark text colors to ensure readability */
  .text-gray-900,
  .text-gray-800,
  .text-gray-700,
  .text-black {
    color: var(--color-text) !important;
  }
  
  .text-gray-600,
  .text-gray-500 {
    color: var(--color-text-muted) !important;
  }
  
  /* Force headings to use our heading color */
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--color-heading) !important;
  }
  
  /* Extra specificity for important elements */
  .post-content p,
  .post-content li,
  .article-content p,
  .article-content li,
  .micro-post-content p {
    color: var(--color-text) !important;
  }
}

/* Dark mode override for body - separated to avoid nesting errors */
@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--color-background);
    color: var(--color-text);
  }
}


/* Force proper text colors in light mode */
html.light-mode .text-gray-900,
html.light-mode .text-gray-800,
html.light-mode .text-gray-700,
html.light-mode .text-black {
  color: var(--color-text) !important;
}

html.light-mode .text-gray-600,
html.light-mode .text-gray-500 {
  color: var(--color-text-muted) !important;
}

/* Extra specificity for important elements in light mode */
html.light-mode .post-content p,
html.light-mode .post-content li,
html.light-mode .article-content p,
html.light-mode .article-content li,
html.light-mode .micro-post-content p {
  color: var(--color-text) !important;
}

/* Fix for code blocks in light mode */
html.light-mode pre {
  background-color: #f5f5f5 !important;
  border: 1px solid #e0e0e0 !important;
  color: #333333 !important;
}

html.light-mode pre code {
  color: #333333 !important;
}

/* Fix for images in light mode */
html.light-mode img {
  opacity: 1;
}
