/**
 * Utility classes
 * Helper classes for common tasks
 */

/* Display utilities */
.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

/**
 * Screen Reader Only
 * Visually hide an element while keeping it accessible to screen readers
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Width and height */
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

/* Positioning */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

/* Z-index */
.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

/* Text alignment and transformation */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

/* Font styles */
.font-thin {
  font-weight: 100;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

/* Rounded corners */
.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: var(--radius-sm);
}

.rounded {
  border-radius: var(--radius);
}

.rounded-md {
  border-radius: var(--radius-md);
}

.rounded-lg {
  border-radius: var(--radius-lg);
}

.rounded-full {
  border-radius: 9999px;
}

/* Box shadows */
.shadow-none {
  box-shadow: none;
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow {
  box-shadow: var(--shadow);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

/* Border utilities */
.border {
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-border);
}

.border-t {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-border);
}

.border-r {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: var(--color-border);
}

.border-b {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-border);
}

.border-l {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-border);
}

/* Opacity utilities */
.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

/* Background and colors */
.bg-transparent {
  background-color: transparent;
}

.bg-current {
  background-color: currentColor;
}

.text-white {
  color: white;
}

.text-primary {
  color: var(--color-primary);
}

.bg-primary {
  background-color: var(--color-primary);
}