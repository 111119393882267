/**
 * Layout styles
 * Container, grid, and overall layout components
 */

/* Main container */
.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
}

@media (min-width: 640px) {
  .container {
    padding-left: var(--spacing-6);
    padding-right: var(--spacing-6);
  }
}

@media (min-width: 768px) {
  .container {
    padding-left: var(--spacing-8);
    padding-right: var(--spacing-8);
  }
}

/* Grid layouts */
.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--spacing-6);
}

@media (min-width: 640px) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Flex layouts */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1 1 0%;
}

/* Spacing utilities */
.m-0 {
  margin: 0;
}

.my-4 {
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
}

.my-8 {
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-8);
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-4 {
  margin-top: var(--spacing-4);
}

.mb-4 {
  margin-bottom: var(--spacing-4);
}

.mt-8 {
  margin-top: var(--spacing-8);
}

.mb-8 {
  margin-bottom: var(--spacing-8);
}

/* Sections */
.section {
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-8);
}

/* Page header */
.page-header {
  padding-top: var(--spacing-6);
  padding-bottom: var(--spacing-6);
  margin-bottom: var(--spacing-8);
  border-bottom: 1px solid var(--color-border);
}

/* Post layouts */
.post {
  margin-bottom: var(--spacing-10);
}

.post-header {
  margin-bottom: var(--spacing-8);
}

.post-content {
  margin-bottom: var(--spacing-8);
}

.post-meta {
  margin-top: var(--spacing-4);
  color: var(--color-text-muted);
  font-size: 0.875rem;
}
