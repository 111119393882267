:root {
  --spacing-1: .25rem;
  --spacing-2: .5rem;
  --spacing-3: .75rem;
  --spacing-4: 1rem;
  --spacing-6: 1.5rem;
  --spacing-8: 2rem;
  --spacing-10: 2.5rem;
  --spacing-12: 3rem;
  --radius-sm: .125rem;
  --radius: .25rem;
  --radius-md: .375rem;
  --radius-lg: .5rem;
  --font-sans: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-heading: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-mono: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  line-height: 1.5;
}

::selection {
  background-color: var(--color-primary);
  color: #fff;
}

:root {
  --color-primary: #e51843;
  --color-primary-light: #ef6281;
  --color-primary-dark: #c9163d;
  --light-color-background: #fff;
  --light-color-text: #333;
  --light-color-text-muted: #718096;
  --light-color-heading: #111827;
  --light-color-surface: #fff;
  --light-color-surface-hover: #f7fafc;
  --light-color-border: #e2e8f0;
  --light-color-border-light: #edf2f7;
  --light-color-code-bg: #f7fafc;
  --light-color-code-text: #333;
  --light-color-header-text: #000;
  --light-shadow-sm: 0 1px 2px 0 #0000000d;
  --light-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  --light-shadow-md: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  --dark-color-background: #121212;
  --dark-color-text: #f0f0f0;
  --dark-color-text-muted: #cbd5e0;
  --dark-color-heading: #fff;
  --dark-color-surface: #1e293b;
  --dark-color-surface-hover: #334155;
  --dark-color-border: #3d4852;
  --dark-color-border-light: #606f7b;
  --dark-color-code-bg: #2d3748;
  --dark-color-code-text: #f8f8f2;
  --dark-color-header-bg: #121212;
  --dark-color-header-text: #fff;
  --dark-shadow-sm: 0 1px 2px 0 #00000040;
  --dark-shadow: 0 1px 3px 0 #0000004d, 0 1px 2px 0 #0003;
  --dark-shadow-md: 0 4px 6px -1px #0000004d, 0 2px 4px -1px #0003;
  --color-background: var(--light-color-background);
  --color-text: var(--light-color-text);
  --color-text-muted: var(--light-color-text-muted);
  --color-heading: var(--light-color-heading);
  --color-surface: var(--light-color-surface);
  --color-surface-hover: var(--light-color-surface-hover);
  --color-border: var(--light-color-border);
  --color-border-light: var(--light-color-border-light);
  --color-code-bg: var(--light-color-code-bg);
  --color-code-text: var(--light-color-code-text);
  --color-header-text: var(--light-color-header-text);
  --shadow-sm: var(--light-shadow-sm);
  --shadow: var(--light-shadow);
  --shadow-md: var(--shadow-md);
}

html.dark-mode {
  --color-background: var(--dark-color-background) !important;
  --color-text: var(--dark-color-text) !important;
  --color-text-muted: var(--dark-color-text-muted) !important;
  --color-heading: var(--dark-color-heading) !important;
  --color-surface: var(--dark-color-surface) !important;
  --color-surface-hover: var(--dark-color-surface-hover) !important;
  --color-border: var(--dark-color-border) !important;
  --color-border-light: var(--dark-color-border-light) !important;
  --color-code-bg: var(--dark-color-code-bg) !important;
  --color-code-text: var(--dark-color-code-text) !important;
  --color-header-bg: var(--dark-color-header-bg) !important;
  --color-header-text: var(--dark-color-header-text) !important;
  --shadow-sm: var(--dark-shadow-sm) !important;
  --shadow: var(--dark-shadow) !important;
  --shadow-md: var(--dark-shadow-md) !important;
}

html.dark-mode .site-header:before {
  background-color: #00000080 !important;
}

html.dark-mode img {
  opacity: .9 !important;
}

html.dark-mode pre {
  color: #f8f8f2 !important;
  background-color: #282a36 !important;
  border: 1px solid #44475a !important;
}

html.dark-mode pre code {
  color: #f8f8f2 !important;
}

html.light-mode {
  --color-background: var(--light-color-background) !important;
  --color-text: var(--light-color-text) !important;
  --color-text-muted: var(--light-color-text-muted) !important;
  --color-heading: var(--light-color-heading) !important;
  --color-surface: var(--light-color-surface) !important;
  --color-surface-hover: var(--light-color-surface-hover) !important;
  --color-border: var(--light-color-border) !important;
  --color-border-light: var(--light-color-border-light) !important;
  --color-code-bg: var(--light-color-code-bg) !important;
  --color-code-text: var(--light-color-code-text) !important;
  --color-header-text: var(--light-color-header-text) !important;
  --shadow-sm: var(--light-shadow-sm) !important;
  --shadow: var(--light-shadow) !important;
  --shadow-md: var(--light-shadow-md) !important;
}

html.dark-mode .text-gray-900, html.dark-mode .text-gray-800, html.dark-mode .text-gray-700, html.dark-mode .text-black {
  color: var(--color-text) !important;
}

html.dark-mode .text-gray-600, html.dark-mode .text-gray-500 {
  color: var(--color-text-muted) !important;
}

html.light-mode .post-content p, html.light-mode .post-content li, html.light-mode .article-content p, html.light-mode .article-content li, html.light-mode .micro-post-content p, html.dark-mode .post-content p, html.dark-mode .post-content li, html.dark-mode .article-content p, html.dark-mode .article-content li, html.dark-mode .micro-post-content p {
  color: var(--color-text) !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  color: var(--color-heading);
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-2);
  font-weight: 600;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

a, a:active, a:hover, a:visited {
  color: var(--color-primary-light);
  text-decoration: none;
  transition: color .2s;
}

a:hover {
  color: var(--color-primary);
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, h6 a:focus, h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active, h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {
  color: var(--color-heading);
  text-decoration: none;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  color: var(--color-primary);
}

p {
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
}

.post-content p {
  margin-bottom: var(--spacing-6);
}

p code, a code {
  background-color: var(--color-code-bg);
  color: var(--color-text);
  border-radius: var(--radius-sm);
  padding: .2em .4em;
  font-size: .9em;
  font-weight: 600;
}

a code {
  color: var(--color-primary-dark);
}

.post-content ul, .post-content ol {
  margin-bottom: var(--spacing-4);
  padding-left: var(--spacing-6);
}

.post-content ul li, .post-content ol li {
  margin-bottom: var(--spacing-2);
}

.post-content ul {
  list-style-type: disc;
}

.post-content ol {
  list-style-type: decimal;
}

.post-content li ul, .post-content li ol {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  margin-left: var(--spacing-4);
}

blockquote {
  border-left: 4px solid var(--color-border);
  margin: var(--spacing-8) var(--spacing-4);
  padding-left: var(--spacing-4);
  color: var(--color-text-muted);
  font-style: italic;
}

blockquote p {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}

hr {
  border: 0;
  border-top: 1px solid var(--color-border);
  margin: var(--spacing-6) 0;
  clear: both;
}

table {
  width: 100%;
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
  border-collapse: collapse;
}

table th {
  background-color: var(--color-surface-hover);
  padding: var(--spacing-2);
  text-align: left;
  font-weight: 600;
}

table td {
  border: 1px solid var(--color-border-light);
  padding: var(--spacing-2);
}

.text-muted {
  color: var(--color-text-muted);
}

.text-small {
  font-size: .875rem;
}

.container {
  max-width: 1200px;
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 640px) {
  .container {
    padding-left: var(--spacing-6);
    padding-right: var(--spacing-6);
  }
}

@media (width >= 768px) {
  .container {
    padding-left: var(--spacing-8);
    padding-right: var(--spacing-8);
  }
}

.grid {
  gap: var(--spacing-6);
  grid-template-columns: repeat(1, 1fr);
  display: grid;
}

@media (width >= 640px) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 768px) {
  .grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 1024px) {
  .grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.m-0 {
  margin: 0;
}

.my-4 {
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
}

.my-8 {
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-8);
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-4 {
  margin-top: var(--spacing-4);
}

.mb-4 {
  margin-bottom: var(--spacing-4);
}

.mt-8 {
  margin-top: var(--spacing-8);
}

.mb-8 {
  margin-bottom: var(--spacing-8);
}

.section {
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-8);
}

.page-header {
  padding-top: var(--spacing-6);
  padding-bottom: var(--spacing-6);
  margin-bottom: var(--spacing-8);
  border-bottom: 1px solid var(--color-border);
}

.post {
  margin-bottom: var(--spacing-10);
}

.post-header, .post-content {
  margin-bottom: var(--spacing-8);
}

.post-meta {
  margin-top: var(--spacing-4);
  color: var(--color-text-muted);
  font-size: .875rem;
}

.main-nav {
  background-color: var(--color-surface);
  border-bottom: 1px solid var(--color-border);
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
  position: relative;
}

.nav-logo {
  z-index: 20;
  align-items: center;
  display: flex;
}

.nav-logo img {
  width: auto;
  max-height: 3rem;
}

.nav-links {
  align-items: center;
  display: flex;
}

.nav-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  transition: transform .3s;
  display: flex;
}

.nav-item {
  margin: 0 .5rem;
}

.nav-link {
  color: var(--color-text);
  border-bottom: 2px solid #0000;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: color .2s, border-color .2s;
  display: inline-block;
}

.nav-link:hover, .nav-link.active {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.theme-switcher {
  align-items: center;
  display: flex;
}

.theme-toggle {
  cursor: pointer;
  color: var(--color-text);
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  transition: background-color .2s, color .2s;
  display: flex;
}

.theme-toggle:hover {
  background-color: var(--color-surface-hover);
  color: var(--color-primary);
}

.theme-icon {
  font-size: 1.25rem;
  display: none;
}

.theme-icon.active {
  display: inline-block;
}

.nav-toggle {
  cursor: pointer;
  z-index: 20;
  background: none;
  border: none;
  padding: .5rem;
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.hamburger {
  width: 2rem;
  height: 1.5rem;
  display: block;
  position: relative;
}

.hamburger span {
  background-color: var(--color-text);
  opacity: 1;
  border-radius: 3px;
  width: 100%;
  height: 3px;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  display: block;
  position: absolute;
  left: 0;
  transform: rotate(0);
}

.hamburger span:first-child {
  top: 0;
}

.hamburger span:nth-child(2), .hamburger span:nth-child(3) {
  top: .75rem;
}

.hamburger span:nth-child(4) {
  top: 1.5rem;
}

.nav-toggle.is-active .hamburger span:first-child, .nav-toggle.is-active .hamburger span:nth-child(4) {
  opacity: 0;
}

.nav-toggle.is-active .hamburger span:nth-child(2) {
  transform: rotate(45deg);
}

.nav-toggle.is-active .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
}

@media (width <= 768px) {
  .nav-toggle {
    display: block;
    top: 1rem;
  }

  .nav-menu {
    background-color: var(--color-surface);
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    transition: transform .3s;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    box-shadow: 0 0 15px #0000001a;
  }

  .nav-menu.is-active {
    transform: translateX(0);
  }

  .nav-item {
    margin: 1rem 0;
  }

  .nav-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
  }

  body.nav-open {
    overflow: hidden;
  }
}

@media (prefers-color-scheme: dark) {
  .main-nav {
    background-color: var(--color-surface);
    border-color: var(--color-border);
  }

  .hamburger span {
    background-color: var(--color-text);
  }

  .nav-menu {
    background-color: var(--color-surface);
  }
}

.site-header {
  color: var(--color-header-text);
  text-align: center;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
  overflow: hidden;
}

header.relative {
  display: block;
  position: relative;
}

.site-header h1 {
  z-index: 2;
  margin-top: 0;
  padding-top: 2.5rem;
  position: relative;
}

@media (width >= 1024px) {
  .site-header h1 {
    padding-top: 8rem;
  }
}

.site-title {
  background-color: var(--color-primary);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: default;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: bold;
  font-family: var(--font-heading);
  padding: .5rem;
  display: inline-block;
}

.site-description {
  color: var(--color-header-text);
  text-shadow: 0 2px 3px #0000004d;
  font-family: var(--font-sans);
  text-align: center;
  text-transform: uppercase;
  cursor: default;
  text-rendering: optimizelegibility;
  margin: 1.25rem 0 0;
  padding: 0 0 5rem;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
}

@media (width >= 640px) {
  .site-description {
    padding-bottom: 8rem;
    font-size: 3.75rem;
    font-weight: 700;
  }
}

@media (width >= 1024px) {
  .site-description {
    padding-bottom: 10rem;
    font-size: 6rem;
    font-weight: 700;
  }
}

.btn {
  padding: var(--spacing-2) var(--spacing-4);
  border-radius: var(--radius);
  text-align: center;
  cursor: pointer;
  border: 1px solid #0000;
  font-weight: 600;
  transition: all .2s;
  display: inline-block;
}

.btn-primary {
  background-color: var(--color-primary);
  color: #fff;
}

.btn-primary:hover {
  background-color: var(--color-primary-dark);
  color: #fff;
}

.btn-outline {
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: #0000;
}

.btn-outline:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.card {
  background-color: var(--color-surface);
  border-radius: var(--radius);
  border: 1px solid var(--color-border);
  transition: all .2s;
  overflow: hidden;
}

.card:hover {
  box-shadow: var(--shadow-md);
}

.card-header {
  padding: var(--spacing-4);
  border-bottom: 1px solid var(--color-border);
}

.card-body {
  padding: var(--spacing-4);
}

.card-footer {
  padding: var(--spacing-4);
  border-top: 1px solid var(--color-border);
}

article {
  margin-bottom: var(--spacing-8);
}

.article {
  margin-bottom: var(--spacing-10);
}

.article-title {
  margin-bottom: var(--spacing-2);
  font-size: 1.75rem;
}

.article-meta {
  color: var(--color-text-muted);
  margin-bottom: var(--spacing-4);
  font-size: .875rem;
}

.article-excerpt {
  margin-bottom: var(--spacing-4);
}

.nav-menu .nav-link {
  padding: var(--spacing-2) var(--spacing-4);
  font-weight: 600;
  display: inline-block;
}

.nav-logo {
  font-size: 1.25rem;
  font-weight: 700;
}

pre {
  background-color: var(--color-code-bg);
  border-radius: var(--radius);
  padding: var(--spacing-4);
  margin: var(--spacing-4) 0;
  overflow-x: auto;
}

div.code-toolbar > .toolbar {
  flex-direction: row;
  align-items: center;
  display: flex;
}

div.code-toolbar > .toolbar .toolbar-item {
  display: unset;
  padding: 0 .3em;
}

.token.operator {
  background: none;
}

.tag {
  background-color: var(--color-surface-hover);
  border-radius: var(--radius-lg);
  padding: var(--spacing-1) var(--spacing-3);
  margin-right: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  font-size: .75rem;
  font-weight: 600;
  display: inline-block;
}

.tag:hover {
  background-color: var(--color-primary-light);
  color: #fff;
}

.pagination {
  margin: var(--spacing-8) 0;
  justify-content: center;
  padding: 0;
  list-style: none;
  display: flex;
}

.pagination-item {
  margin: 0 var(--spacing-1);
}

.pagination-link {
  padding: var(--spacing-2) var(--spacing-3);
  border: 1px solid var(--color-border);
  border-radius: var(--radius);
  display: block;
}

.pagination-link.active {
  background-color: var(--color-primary);
  color: #fff;
  border-color: var(--color-primary);
}

.pagination-link:hover:not(.active) {
  background-color: var(--color-surface-hover);
}

.micro-post {
  border: 1px solid var(--color-border);
  border-radius: var(--radius);
  margin-bottom: var(--spacing-6);
  background-color: var(--color-surface);
  max-width: 48rem;
  box-shadow: var(--shadow-sm);
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  transition: all .2s;
  position: relative;
  overflow: hidden;
}

.micro-post:hover {
  box-shadow: var(--shadow-md);
}

.micro-post-inner {
  padding: var(--spacing-4);
}

.micro-post-content {
  color: var(--color-text);
  font-size: 1.125rem;
  line-height: 1.75;
}

.micro-post-content p {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.micro-post-content p:first-of-type {
  margin-top: 0;
}

.micro-post-content p:last-of-type {
  margin-bottom: 0;
}

.article + .micro-post, .micro-post + .article {
  margin-top: 2.5rem;
}

.micro-post:before {
  background-color: var(--color-surface);
  color: var(--color-text-muted);
  border: 1px solid var(--color-border);
  border-radius: var(--radius);
  z-index: 1;
  padding: .125rem .5rem;
  font-size: .6875rem;
  font-weight: 600;
  display: inline-block;
  position: absolute;
  top: -.625rem;
  left: 1rem;
}

.container .micro-post {
  margin-left: auto;
  margin-right: auto;
}

@media (width <= 640px) {
  .micro-post {
    padding: var(--spacing-3);
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .micro-post:before {
    padding: .125rem .375rem;
    font-size: .625rem;
    left: .75rem;
  }
}

.micro-post-single {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.micro-post.micro-post-item img {
  object-fit: cover;
  border-radius: var(--radius-md);
  max-width: 100%;
  max-height: 200px;
  margin: .75rem 0;
  transition: opacity .2s;
}

.micro-post-image-link {
  display: inline-block;
  position: relative;
}

.micro-post-image-link:hover .micro-post-thumbnail {
  opacity: .95;
}

.micro-post-image-link:after {
  content: "↗";
  color: var(--color-text);
  border-radius: var(--radius);
  opacity: 0;
  background-color: #ffffffd9;
  padding: .25rem .5rem;
  font-size: .875rem;
  transition: opacity .2s;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

.micro-post-image-link:hover:after {
  opacity: 1;
}

.micro-post-with-thumbnails p:has(.micro-post-image-link) {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.micro-post-with-thumbnails p .micro-post-image-link {
  flex: auto;
  min-width: 150px;
  max-width: 100%;
}

@media (width >= 640px) {
  .micro-post-with-thumbnails p .micro-post-image-link {
    max-width: 48%;
  }
}

.micro-post-meta {
  color: var(--color-text-muted);
  margin-top: var(--spacing-2);
  justify-content: space-between;
  align-items: center;
  font-size: .875rem;
  display: flex;
}

.micro-post-date {
  font-weight: 600;
}

.micro-post-permalink {
  opacity: .7;
  font-size: .75rem;
  transition: opacity .2s;
}

.micro-post-permalink:hover {
  opacity: 1;
}

.micro-post-footer {
  border-top: 1px solid var(--color-border);
  color: var(--color-text-muted);
  flex-direction: column;
  margin-top: 1rem;
  padding-top: 1rem;
  font-size: .875rem;
  display: flex;
}

.micro-post-time {
  font-weight: 500;
}

.micro-post-time:hover {
  color: var(--color-primary);
}

.micro-post-tags {
  margin-top: .75rem;
}

.micro-post-tag {
  border-radius: var(--radius-md);
  background-color: var(--color-surface-hover);
  color: var(--color-text-muted);
  margin-bottom: .25rem;
  margin-right: .5rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  text-decoration: none;
  transition: background-color .2s;
  display: inline-block;
}

.micro-post-tag:hover {
  background-color: var(--color-border-light);
}

@media (width >= 640px) {
  .micro-post-footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .micro-post-tags {
    margin-top: 0;
  }
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.font-thin {
  font-weight: 100;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: var(--radius-sm);
}

.rounded {
  border-radius: var(--radius);
}

.rounded-md {
  border-radius: var(--radius-md);
}

.rounded-lg {
  border-radius: var(--radius-lg);
}

.rounded-full {
  border-radius: 9999px;
}

.shadow-none {
  box-shadow: none;
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow {
  box-shadow: var(--shadow);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.border {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-border);
}

.border-t {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--color-border);
}

.border-r {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: var(--color-border);
}

.border-b {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--color-border);
}

.border-l {
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--color-border);
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.opacity-100 {
  opacity: 1;
}

.bg-transparent {
  background-color: #0000;
}

.bg-current {
  background-color: currentColor;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: var(--color-primary);
}

.bg-primary {
  background-color: var(--color-primary);
}

@media (prefers-color-scheme: dark) {
  h1, h2, h3, h4, h5, h6 {
    color: var(--color-heading);
  }

  a, a:active, a:hover, a:visited {
    color: var(--color-primary-light);
  }

  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, h6 a:focus, h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active, h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {
    color: var(--color-heading);
  }

  p code, a code {
    color: #f8f8f2;
    border: 1px solid var(--color-border);
    background-color: #2d3748b3;
  }

  a code {
    color: var(--color-primary-light);
  }

  pre {
    color: #f8f8f2 !important;
    background-color: #282a36 !important;
    border: 1px solid #44475a !important;
  }

  pre code {
    color: #f8f8f2 !important;
  }

  table th {
    background-color: var(--color-surface-hover);
  }

  table td {
    border-color: var(--color-border);
  }

  .btn-outline {
    border-color: var(--color-primary-light);
    color: var(--color-primary-light);
  }

  .card {
    background-color: var(--color-surface);
    border-color: var(--color-border);
  }

  .card-header, .card-footer {
    border-color: var(--color-border);
  }

  .tag {
    background-color: var(--color-surface-hover);
    color: var(--color-text);
  }

  .micro-post {
    background-color: var(--color-surface);
    border-color: var(--color-border);
  }

  .micro-post:before {
    background-color: var(--color-surface);
    color: var(--color-text-muted);
    border-color: var(--color-border);
  }

  .micro-post-content {
    color: var(--color-text);
  }

  .micro-post-image-link:after {
    color: var(--color-text);
    background-color: #2d3748d9;
  }

  input, textarea, select {
    background-color: var(--color-surface);
    border-color: var(--color-border);
    color: var(--color-text);
  }

  input:focus, textarea:focus, select:focus {
    border-color: var(--color-primary);
  }

  .pagination-link {
    border-color: var(--color-border);
    color: var(--color-text);
  }

  .pagination-link:hover:not(.active) {
    background-color: var(--color-surface-hover);
  }

  img {
    opacity: .9;
  }

  .text-gray-900, .text-gray-800, .text-gray-700, .text-black {
    color: var(--color-text) !important;
  }

  .text-gray-600, .text-gray-500 {
    color: var(--color-text-muted) !important;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--color-heading) !important;
  }

  .post-content p, .post-content li, .article-content p, .article-content li, .micro-post-content p {
    color: var(--color-text) !important;
  }

  body {
    background-color: var(--color-background);
    color: var(--color-text);
  }
}

html.light-mode .text-gray-900, html.light-mode .text-gray-800, html.light-mode .text-gray-700, html.light-mode .text-black {
  color: var(--color-text) !important;
}

html.light-mode .text-gray-600, html.light-mode .text-gray-500 {
  color: var(--color-text-muted) !important;
}

html.light-mode .post-content p, html.light-mode .post-content li, html.light-mode .article-content p, html.light-mode .article-content li, html.light-mode .micro-post-content p {
  color: var(--color-text) !important;
}

html.light-mode pre {
  color: #333 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #e0e0e0 !important;
}

html.light-mode pre code {
  color: #333 !important;
}

html.light-mode img {
  opacity: 1;
}

.post-content {
  font-size: 1rem;
  font-family: var(--font-sans);
  max-width: 56rem;
  margin: 0 auto;
  padding: 0;
  display: block;
  overflow-x: hidden;
}

.post-content-inner {
  margin: 0 auto;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.post-header {
  text-align: center;
  max-width: 56rem;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 2.5rem;
}

.page-header {
  color: var(--color-header-text);
  position: relative;
}

.page-content {
  font-size: 1rem;
  font-family: var(--font-sans);
  max-width: 56rem;
  margin: 0 auto;
  padding: 0 0 5rem;
  display: block;
  overflow-x: hidden;
}

.feed-container {
  max-width: 56rem;
  margin: 0 auto;
  padding: .5rem 1rem 1rem;
}

.feed-link-wrapper {
  justify-content: center;
  width: 100%;
  display: flex;
}

.feed-link {
  color: var(--color-text-muted);
  white-space: nowrap;
  align-items: center;
  font-size: .875rem;
  font-weight: 500;
  transition: color .2s;
  display: inline-flex;
}

.feed-link:hover {
  color: var(--color-primary);
}

.feed-icon {
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;
}

.post-title {
  color: var(--color-text);
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 800;
  font-family: var(--font-heading);
  margin: 0 auto;
}

.post-meta {
  font-size: 1.25rem;
  font-family: var(--font-sans);
  margin: 0 auto 2rem;
}

.post-item {
  border-bottom: 1px solid var(--color-border);
  max-width: 56rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2.5rem;
  padding-bottom: 3rem;
}

.post-item:first-child {
  padding-top: 1.5rem;
}

.post-item-date-column {
  margin-bottom: 1rem;
}

.post-item-date {
  align-items: baseline;
  display: flex;
}

.post-item-day {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: var(--font-heading);
  color: var(--color-primary);
  margin-right: .5rem;
}

.post-item-month-year {
  color: var(--color-text-muted);
  font-size: .875rem;
  font-family: var(--font-heading);
  text-transform: uppercase;
}

.post-item-title {
  color: var(--color-heading);
  font-weight: 700;
  font-family: var(--font-heading);
  margin-bottom: .75rem;
  font-size: 1.5rem;
}

.post-item-title a {
  transition: color .2s;
}

.post-item-title a:hover {
  color: var(--color-primary);
}

.post-item-excerpt {
  color: var(--color-text-muted);
  font-size: 1rem;
  font-family: var(--font-sans);
  margin-bottom: 1rem;
}

.post-item-categories {
  font-size: .75rem;
  font-family: var(--font-sans);
}

.post-item-category {
  color: var(--color-text-muted);
  border: 1px solid var(--color-border);
  border-radius: .375rem;
  margin-bottom: .25rem;
  margin-right: .25rem;
  padding: .25rem .75rem;
  transition: background-color .2s;
  display: inline-block;
}

.post-item-category:hover {
  background-color: var(--color-surface-hover);
}

@media (width >= 640px) {
  .post-title {
    font-size: 4.5rem;
  }

  .post-item {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .post-item:first-child {
    padding-top: 2rem;
  }

  .post-item-flex {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
  }

  .post-item-date-column {
    width: auto;
    margin-bottom: 0;
    margin-right: 1.5rem;
  }

  .post-item-date {
    text-align: center;
    display: block;
  }

  .post-item-day {
    margin-right: 0;
    font-size: 3rem;
  }

  .post-item-content {
    flex: 1;
  }

  .post-item-title {
    font-size: 1.875rem;
  }

  .post-item-excerpt {
    font-size: 1.125rem;
  }
}

@media (width >= 768px) {
  .post-item-date-column {
    margin-right: 2rem;
  }
}

.author-section {
  margin: 0;
  padding: 3.5rem 0;
}

.author-content {
  width: 100%;
  margin: 0 auto;
}

.author-inner {
  text-align: center;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.author-avatar {
  vertical-align: middle;
  border-radius: 9999px;
  width: 8rem;
  height: 8rem;
  margin-bottom: 1.25rem;
  margin-right: 0;
  display: inline-block;
}

.author-label {
  color: var(--color-text-muted);
  font-size: 1rem;
  font-style: italic;
  display: block;
}

.author-name {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.author-email {
  margin-bottom: 2rem;
}

.author-email a, .author-email a:hover, .author-email a:focus {
  color: var(--color-primary);
}

.author-bio {
  font-family: var(--font-sans);
  font-size: 1.125rem;
}

@media (width >= 768px) {
  .author-avatar {
    width: 12rem;
    height: 12rem;
  }
}

footer {
  background-color: #000;
  position: relative;
}

.footer-coffee {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

footer:after {
  content: "";
  clear: both;
  display: table;
}

.footer-copyright {
  color: #fff;
  text-align: center;
  padding: 1.5rem;
  font-size: .875rem;
}

.footer-social {
  text-align: center;
  padding: 1.5rem;
}

@media (width >= 768px) {
  footer {
    flex-wrap: wrap;
    display: flex;
  }

  .footer-coffee {
    width: 100%;
  }

  .footer-copyright, .footer-social {
    flex-direction: row;
    justify-content: center;
    padding: 2rem;
    display: flex;
  }

  .footer-copyright {
    text-align: left;
    flex: 1;
  }

  .footer-social {
    text-align: right;
  }
}

@media (width >= 1024px) {
  .footer-copyright, .footer-social {
    padding: 3rem;
  }
}

.bmc-button {
  letter-spacing: .6px;
  box-sizing: border-box;
  background-color: #ff813f;
  border: 1px solid #0000;
  border-radius: 3px;
  height: 37px;
  margin: 0 auto;
  padding: 1px 9px;
  font-family: Cookie, cursive;
  font-size: 22px;
  line-height: 36px;
  text-decoration: none;
  transition: all .3s linear;
  display: inline-flex;
  box-shadow: 0 1px 2px #bebebe80;
  color: #fff !important;
}

.bmc-button:hover, .bmc-button:active, .bmc-button:focus {
  opacity: .85;
  color: #fff;
  text-decoration: none;
  box-shadow: 0 1px 2px 2px #bebebe80;
}

.bmc-button img {
  width: 27px;
  box-shadow: none;
  vertical-align: middle;
  border: none;
  margin-bottom: 1px;
}

.bmc-button span {
  margin-left: 5px;
}

.footer-copyright a, .footer-social a {
  text-decoration: none;
}

.footer-copyright a:hover, .footer-social a:hover {
  color: var(--color-primary-light);
}

.footer-copyright section {
  margin-bottom: .5rem;
}

.fa-stack {
  vertical-align: middle;
  width: 2em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.imgBlock {
  display: block;
}

.imgFigCaption {
  hyphens: none;
  text-align: center;
  width: min-content;
  min-width: 100%;
}

.imgImg {
  margin-top: .5em;
}

.imgWrapper > figure > figcaption {
  margin-top: 0;
}

.imgFlex {
  display: flex;
}

.imgOuterDiv > figure {
  margin: 0;
  padding: 0;
}

.imgPicture {
  width: 100%;
  height: auto;
}

.imgWrapper {
  vertical-align: top;
  margin-bottom: 1em;
}

.imgWrapper figure {
  margin: 0;
}

li > .imgWrapper {
  margin-left: 1em;
}

.imgWrapper.center {
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.imgWrapper.imgBlock {
  display: block;
}

.imgWrapper.inline {
  display: inline-block;
}

.imgImg.rounded {
  border: 1px solid;
  border-radius: 7px;
}

.imgImg.shadow {
  filter: progid:DXImageTransform. Microsoft. Shadow(Strength= 6, Direction= 135, Color= "#999999");
  border: thin solid gray;
  margin-bottom: 10px;
  box-shadow: 5px 5px 6px #999;
}

.highlight table.rouge-table {
  background: inherit;
  border: 0;
}

.highlight table.rouge-table td {
  color: inherit;
}

.align-super {
  vertical-align: super;
}

.clear-table {
  clear: both;
}
/*# sourceMappingURL=style.css.map */
