/**
  * jekyll_img plugin CSS styles
  * pulled from https://github.com/mslinn/jekyll_img/blob/master/demo/assets/css/jekyll_img.css
  * I did not include all of them
  */

.imgBlock {
  display: block;
}

/* Center captions within div.imgWrapper */
.imgFigCaption {
  hyphens: none;
  min-width: 100%;
  text-align: center;
  width: min-content;
}

/* Marker class applied to <img> tag */
.imgImg {
  margin-top: 0.5em;
}

.imgWrapper > figure > figcaption {
  margin-top: 0;
}

.imgImgUrl { }            /* Marker class, indicates a URL was specified */

.imgFigCaption > a { }    /* Only applies to URLs in captions */

.imgFlex {
  display: flex;
}

.imgOuterDiv > figure {
  margin: 0;
  padding: 0;
}

.imgPicture {             /* Marker class, applies to <picture> element */
  height: auto;
  width: 100%;
}

.imgPicture > img { }     /* placeholder */

/* Marker class, indicates a caption was specified */
.imgWrapper {
  margin-bottom: 1em;
  vertical-align: top;
}

.imgWrapper figure {
  margin: 0;
}

li > .imgWrapper {
  margin-left: 1em;
}

.imgWrapper.center {
  display: block;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.imgWrapper.imgBlock {
  display: block;
}

.imgWrapper.inline {
  display: inline-block;
}

.imgImg.rounded {
  border: 1px solid;
  border-radius: 7px;
}

.imgImg.shadow {
  border: thin gray solid;
  box-shadow: 5px 5px 6px #999;
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=6, Direction=135, Color='#999999');
  margin-bottom: 10px;
}
